<template>
                 <v-data-table
                  v-bind="$attrs"
                  v-on="inputListeners"
                 >
                  <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
                  <template slot="progress">
            <v-divider/>
          </template>
        <template slot="loading">
        <v-skeleton-loader
          type="table-tbody"
          :types="{ 'table-row': 'table-cell@4' }"
          v-bind="$attrs"
        />
          </template>
                 </v-data-table>
</template>

<script>
export default {
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
