<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined :disabled="!($auth.isSiteAdmin || $auth.isTenantAdmin || $auth.role.isSystemAdmin)">
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <TextField
                  :rules="emailRules"
                  dense
                  :title="'เพิ่มสมาชิก'"
                  v-model="email"
                  :placeholder="'กรอกอีเมล์ของสมาชิก'"
                  :required="true"
                />
              </v-col>
              <v-col cols="12" md="3">
                <DropDownList
                  dense
                  v-model="role"
                  :items="roles"
                  item-text="roleName"
                  item-value="roleId"
                  :title="'สิทธิ์'"
                  :placeholder="'ผู้ดูแลระบบ'"
                  :required="true"
                />
              </v-col>
              <v-col cols="12" md="3">
                <label class="text-title-4 natural--text text--darken-4"
                  >ดำเนินการ
                  <v-btn
                    :loading="loading"
                    @click="inviteUser"
                    color="primary"
                    block
                    >ส่งคำเชิญ</v-btn
                  >
                </label>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card flat outlined :disabled="!($auth.isSiteAdmin || $auth.isTenantAdmin || $auth.role.isSystemAdmin)">
        <v-card-text>
          <DataTable :items="items" :headers="headers" :loading="loading">
            <template v-slot:item.role="{ item }">
              <div class="pt-5" >
                <DropDownList
                  dense
                  :items="roles"
                  v-model="item.isSiteAdmin"
                  item-text="roleName"
                  item-value="roleId"
                  :disabled="!item.editable"
                />
              </div>
            </template>
            <template v-slot:item.action="{ item }">
              <v-row align="center" v-if="!item.editable">
                <v-switch inset v-model="item.active" @change="patchSiteUser(item)"/>
                <v-btn icon @click="reInviteUser(item)">
                  <v-icon>mdi-email-send-outline</v-icon>
                </v-btn>
                <v-btn icon @click="item.editable = true">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-row>
              <!-- <v-btn color="primary" width="169" v-else @click="patchSiteUser(item)">บันทึก</v-btn> -->
                            <v-row align="center" v-else>
                    <v-spacer/>
              <v-btn color="primary" width="70"  @click="patchSiteUser(item)">บันทึก</v-btn>
              <v-spacer/>
              <v-btn outlined color="primary" width="70"  @click="item.editable = false">ยกเลิก</v-btn>
               <v-spacer/>
                 </v-row>
            </template>
            <template slot="no-data">
              <div
                class="text-center text-title-1 natural--text text--darken-4"
              >
                ยังไม่มีสมาชิก
              </div>
              <div
                class="text-center text-title-3 natural--text text--lighten-1 pt-2"
              >
                กรุณากรอกอีเมลที่ช่องด้านบนเพื่อทำการเพิ่มเพื่อนสมาชิก
              </div>
            </template>
          </DataTable>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import TextField from '@/components/common/TextField'
import DropDownList from '@/components/common/DropDownList'
import DataTable from '@/components/common/SubDataTable'
import { getSiteUser, postSiteUser, patchSiteUser } from '@/api/'

export default {
  components: {
    TextField,
    DropDownList,
    DataTable
  },
  data () {
    return {
      headers: [
        { text: 'อีเมล์', value: 'email', class: 'primary lighten-5' },
        {
          text: 'สิทธิ์',
          value: 'role',
          class: 'primary lighten-5',
          width: '200px'
        },
        {
          text: 'ดำเนินการ',
          value: 'action',
          class: 'primary lighten-5',
          width: '200px'
        }
      ],
      items: [],
      total: [],
      roles: [
        { roleId: false, roleName: 'User' },
        { roleId: true, roleName: 'Site Admin' }
      ],
      loading: false,
      email: null,
      role: null,
      valid: false,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  methods: {
    patchSiteUser (item) {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      patchSiteUser({ id: item.id, etag: item._etag, tenantId: tenant.tenantId, siteId: this.$route.params.siteId }, item, message => {
        if (message.data.code === 1) {
          this.fetchData()
          item.editable = false
        }
      }, error => {
        console.log(error)
      })
    },
    reInviteUser (item) {
      this.loading = true
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const data = {
        tenantId: tenant.tenantId,
        siteId: this.$route.params.siteId,
        email: item.email,
        isSiteAdmin: item.isSiteAdmin
      }
      postSiteUser(
        data,
        (message) => {
          // console.log(message)
          if (message.data.code === 1) {
            this.$refs.form.reset()
            this.fetchData()
          }
          this.loading = false
        },
        (error) => {
          this.loading = false
          console.log(error)
        }
      )
    },
    inviteUser () {
      if (this.$auth.role) {
        if (this.$refs.form.validate()) {
          this.loading = true
          const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
          const data = {
            tenantId: tenant.tenantId,
            siteId: this.$route.params.siteId,
            email: this.email,
            isSiteAdmin: this.role
          }
          postSiteUser(
            data,
            (message) => {
              // console.log(message)
              if (message.data.code === 1) {
                this.$refs.form.reset()
                this.fetchData()
              }
              this.loading = false
            },
            (error) => {
              this.loading = false
              console.log(error)
            }
          )
        }
      }
    },
    fetchData () {
      if (this.$auth.role) {
        this.loading = true
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        getSiteUser(
          {
            tenantId: tenant.tenantId,
            siteId: this.$route.params.siteId,
            offset: 0,
            limit: 100
          },
          (message) => {
            if (message.data.code === 1) {
              if (message.data.result) {
                message.data.result.items.forEach(function (element) { element.editable = false })
                this.items = message.data.result.items
                this.total = message.data.result.total
              } else {
                this.items = []
              }
            }
            this.loading = false
          },
          (error) => {
            this.loading = false
            console.log(error)
          }
        )
        // getMasterRole({ keyword: '' }, message => {
        //   if (message.data.code === 1) {
        //     if (message.data.result) {
        //       console.log(message.data.result)
        //       this.roles = message.data.result
        //     } else {
        //       this.roles = []
        //     }
        //   }
        // })
        // console.log(this.$route.params)
      }
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style>
</style>
